



















import Base from '@/mixins/Base.vue';
import { ISpObject, ISpObjectsResponse } from '@/interfaces/spobject';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            objects: {
                data: [] as Array<ISpObject>,
                meta: {} as IPagination | any,
            } as ISpObjectsResponse,
            map: undefined as any,
        };
    },
    mounted() {
        this.loadMap();
        this.getObjects();
    },
    methods: {
        getObjects(): void {
            this.get<ISpObjectsResponse>('objects', {
                params: {
                },
            }).then(({ data }) => {
                this.objects = data;

                if (this.objects && this.objects.data) {
                    const bounds = new (window as any).google.maps.LatLngBounds();

                    this.objects.data.forEach((object: ISpObject) => {
                        if (this.map && object.address && object.address.lat && object.address.lng) {
                            const contentString = '<div id="content">'
                                + `<h1 id="firstHeading" class="firstHeading"><strong>${object.name}</strong> (${object.client.name})</h1>`
                                + '<div id="bodyContent">'
                                + `<p>${object.description || ''}</p>`
                                + '</div>'
                                + '</div>';

                            const infowindow = new (window as any).google.maps.InfoWindow({
                                content: contentString,
                            });

                            const position = new (window as any).google.maps.LatLng(object.address.lat, object.address.lng);

                            const marker = new (window as any).google.maps.Marker({
                                map: this.map,
                            });

                            marker.addListener('dblclick', () => {
                                this.$router.push({ name: 'objects.edit', params: { id: object.id.toString() } });
                            });
                            marker.addListener('click', () => {
                                infowindow.open({
                                    anchor: marker,
                                    map: this.map,
                                    shouldFocus: false,
                                });
                            });

                            marker.setPosition(position);
                            bounds.extend(marker.position);
                        }
                    });

                    this.map.fitBounds(bounds);
                }
                // this.map.setZoom(6);
            });
        },
        loadMap():void {
            if ((window as any).google) {
                this.map = new (window as any).google.maps.Map(this.$refs.map, {
                    center: { lat: 50, lng: 5 },
                    zoom: 8,
                    disableDefaultUI: true,
                });
            }
        },
    },
});

export default component;
