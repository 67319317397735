




































































import Base from '@/mixins/Base.vue';

import { IPartner, IPartnersResponse } from '@/interfaces/partner';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                per_page: 999,

            },
            partners: {
                data: [] as Array<IPartner>,
                meta: {} as IPagination | any,
            } as IPartnersResponse,
        };
    },
    mounted() {
        this.getPartners();
    },
    methods: {
        splicedPartners(index: number) {
            const slices = this.partners.data.slice(3 * (index - 1), (3 * (index - 1)) + 3);
            return slices;
        },
        getPartners(page: number = 1): void {
            this.params.page = page;

            this.get<IPartnersResponse>('partners', {
                params: this.params,
            }).then(({ data }) => {
                this.partners = data;
            });
        },
    },
});

export default component;
