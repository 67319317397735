




























































































import Base from '@/mixins/Base.vue';

import { IBlogPost, IBlogPostsResponse } from '@/interfaces/blogpost';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                per_page: 3,
                is_published: true,

            },
            posts: {
                data: [] as Array<IBlogPost>,
                meta: {} as IPagination | any,
            } as IBlogPostsResponse,
        };
    },
    mounted() {
        this.getBlogPost();
    },
    methods: {
        listenInserts() {
            this.$supabase.from('blog_posts').on('INSERT', () => {
                this.getBlogPost(1);
            }).subscribe();
        },
        getBlogPost(page: number = 1): void {
            this.params.page = page;

            this.get<IBlogPostsResponse>('blog/posts', {
                params: this.params,
            }).then(({ data }) => {
                this.posts = data;
                this.listenInserts();
            });
        },
    },
});

export default component;
