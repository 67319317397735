

















































































































































































































import Base from '@/mixins/Base.vue';

import { IJob, IJobsResponse } from '@/interfaces/job';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    data() {
        return {
            params: {
                sortBy: 'id',
                orderBy: 'asc',
                page: 1,

            },
            jobs: {
                data: [] as Array<IJob>,
                meta: {} as IPagination | any,
            } as IJobsResponse,
        };
    },
    mounted() {
        this.getJobs();
    },
    methods: {
        getJobs(page: number = 1): void {
            this.params.page = page;

            this.get<IJobsResponse>('jobs', {
                params: this.params,
            }).then(({ data }) => {
                this.jobs = data;
            });
        },
    },
});

export default component;
