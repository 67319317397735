









































































import moment from 'moment';
import mixins from 'vue-typed-mixins';

import { IServiceStatistic, IServiceStatisticsResponse } from '@/interfaces/service';
import Base from '@/mixins/Base.vue';
import Colors from '@/mixins/Colors.vue';
import { IClient } from '@/interfaces/client';

const component = mixins(Base, Colors).extend({
    extends: Colors,
    props: {
        active: {
            type: Boolean,
            default: false,
        },

    },
    data() {
        return {
            isAddClientCommentModalActive: false,
            statisticsParams: {
                client: null as IClient | any,
            },
            type: 'line',
            statistics: [] as Array<IServiceStatistic>,
            options: {
                colors: [],
                xaxis: {
                    categories: [],
                },
            } as any,
            colors: ['#15dba5', '#042f3d'],
        };
    },
    mounted() {
        for (let i = 0; i < 12; i += 1) {
            this.options.xaxis.categories.push(moment().subtract(12 - i, 'month').format('MM/YY'));
            this.options.colors.push(this.adjustBrightness(this.colors[i % this.colors.length], i * 30));
        }
        this.getServiceStatistics();
    },
    methods: {
        getServiceStatistics() {
            this.get<IServiceStatisticsResponse>('services/statistics', {
                params: {
                    ...this.statisticsParams,
                    client_id: this.statisticsParams.client ? this.statisticsParams.client.id : null,
                },
            }).then(({ data }) => {
                this.statistics = data.data;
            });
        },

    },
});

export default component;
