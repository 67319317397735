








































































import Base from '@/mixins/Base.vue';
import { IJob, IJobsResponse } from '@/interfaces/job';
import { IPagination } from '@/interfaces/pagination';

import Objects from './MapObjects.vue';
import Statistics from './Statistics.vue';

const component = Base.extend({
    components: {
        Objects,
        Statistics,
    },
    data() {
        return {

            jobs: {
                data: [] as Array<IJob>,
                meta: {} as IPagination | any,
            } as IJobsResponse,

        };
    },
    mounted() {
        this.getJobs();
    },
    methods: {
        getJobs(page: number = 1): void {
            this.get<IJobsResponse>('jobs', {
                params: {
                    page,
                },
            }).then(({ data }) => {
                this.jobs = data;
            });
        },

    },
});

export default component;
