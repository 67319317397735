



































































































































































































































































































































































import moment from 'moment';

import Base from '@/mixins/Base.vue';
import LatestJobs from './dashboard/LatestJobs.vue';
import Summary from './dashboard/Summary.vue';
import BlogPosts from './dashboard/BlogPosts.vue';
import Tips from './dashboard/Tips.vue';
import Partners from './dashboard/Partners.vue';

const component = Base.extend({
    components: {
        LatestJobs,
        Summary,
        BlogPosts,
        Tips,
        Partners,
    },
    data() {
        return {
            moment,
            isIconActive: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isIconActive = true;
        }, 100);
    },
});

export default component;
